<template>
  <div class="section disclaimer__section">
    <div class="container" v-html="item.content"></div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {},
}
</script>

<style lang="scss">
.disclaimer {
  &__section {
    padding-top: 7rem;
    .container {
      max-width: 800px;
    }
  }
}
</style>
